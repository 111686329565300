@import "src/styles/main.scss";

.emailpmi {
  width: 100%;
  height: 50px;
  padding: 12px 0 12px 20px;
  margin-top: 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
  border: 1px solid $light-grey;
  border-radius: 5px;
  outline: none;
  transition: $transition;

  &:focus {
    border: 1px solid $dark-grey;
  }

  &_error {
    border: 2px solid $red;
  }
}

.emailpmi::-webkit-inner-spin-button {
  display: none;
}

.counter {
  max-width: fit-content;
  align-items: center;
  font-weight: 100;
  margin-top: 5.5rem;
  font-size: 2.6rem;
  color: $black;
  letter-spacing: 0.196rem;
  text-transform: uppercase;
  transition: none;
  @include sm() {
    margin-top: 6rem;
    max-width: 100%;
  }
}

.error {
  display: block;
  width: 100%;
  margin-top: 3rem;
  font-size: 2rem;
  color: red;
}

@media all and (max-width: 540px) {
  .emailpmi {
    width: 100%;
    height: 59px;
    font-size: 3rem;
  }
}
