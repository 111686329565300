@import "src/styles/main.scss";

.table {
  @include size(100%);

  display: grid;
  gap: 16px;
  font-size: 16px;
  border-collapse: collapse;
}

.column {
  min-height: 35px;
  background: white;
}

.row {
  display: grid;
  grid-template-columns: min-content 1fr;
  cursor: grab;
  width: 100%;
  background: white;
}

.index {
  padding: 16px;
  border: 1px solid $gray;
  text-align: center;
}

.text {
  @include flex(flex-start, center);

  padding: 1.5rem 2rem;
  border: 1px solid $gray;

  &__active {
    background-color: darken($white, 10%);
  }
}

.source__column {
  @include flex(flex-start, center, row);

  width: 100%;
  flex-wrap: wrap;
}

.source__option {
  width: fit-content;
  height: 60px;
  margin-bottom: 10px;
  margin-right: 20px;
  display: grid;
  grid-template-columns: min-content 1fr;
  cursor: grab;
  background: white;

  &__active {
    background-color: darken($white, 10%);
  }
}

.source__text {
  @extend .text;

  justify-content: center;
  grid-column: 1/3;
}
