@import "src/styles/main.scss";

.survey {
  @include flex($align: stretch, $direction: column);

  flex: 1;
  padding: 30px 30px 70px;
}

.content {
  @include flex(center, stretch, column);

  flex: 1;
  max-width: 540px;
  width: 540px;
  margin: 0px auto;
  scroll-padding: 0px;
  padding-bottom: 106px;

  &__caption {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0;
    color: $dark-grey;

    a:visited,
    a:link {
      color: $black-brown
    }
  }
  &__description {
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    color: $dark-grey;

    a:visited,
    a:link {
      color: $black-brown
    }
  }

  &__section {
    @include flex(center, center, column);

    align-self: center;
    width: 100%;
    margin-top: 25px;
  }

  @include sm() {
    max-width: 100%;
    width: 100%;
  }
}
