@import 'src/styles/main';

.container {
  position: relative;
  margin: 10px 0;
}

.input {
  width: 100%;
  height: 50px;
  padding-top: 4px;
  padding-bottom: 0;
  padding-left: 66px;
  font-family: 'IQOS', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  font-size: 16px;
  background: transparent;
  font-style: normal;
  border-radius: 5px;
  border: 1px solid $light-grey;
  z-index: 2;

  &_with_red_border {
    border: 2px solid $red !important;
  }

  &_focused {
    border: 2px solid $dark-grey;
  }

  &_text {
    position: absolute;
    top: 15px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 25px;
    color: #b8b7bb;
  }

  &_error_icon {
    @include flex(center, center);

    position: absolute;
    top: 15px;
    right: 20px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    background-color: $red;
    color: $white;
    border-radius: 100px;
  }

  &:focus {
    outline: none;
  }

  &_filled {
    border: 1px solid #99979e;
  }
}


.label {
  position: absolute;
  z-index: 12;
  top: 12.5px;
  left: 20px;
  padding: 0 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
  color: $light-grey;
  background-color: $white;
  transition: $transition;

  &_focused {
    top: -8px;
    left: 10px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0;
    color: $dark-grey;
  }

  &_error {
    color: $red;
  }
}
