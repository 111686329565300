@import "src/styles/main.scss";

.phone {
  @include flex(center, flex-start, column);

  flex: 1;
  padding: 30px;
}

.content {
  @include flex(center, center, column);

  max-width: 540px;
  margin: 0 auto;

  @include sm() {
    max-width: 100%;
    align-items: flex-start;
  }
}

.caption {
  color: $dark-grey;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0;
}

.input {
  margin: 25px 0;
  font-family: 'IQOS', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  font-size: 16px;

  &_container {
    width: 100%;
  }
}

.button_disabled {
  background-color: $grey-cloud;
  color: $medium-grey;

  &:after {
    background-color: $grey-cloud;
    color: $medium-grey;
  }
}
