@import "src/styles/main.scss";

.variant {
  @include flex(space-between, center, row);

  flex-wrap: wrap;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #c4c4c4;

  &_one_before_from_last,
  &:last-of-type {
    border: none;
    margin-bottom: 15px;
    padding-bottom: 0;
  }

  &_with_comment {
    grid-template-columns: 1fr;
    border: none;
  }

  &_checkbox {
    width: -webkit-calc(100% - 75px);
  }

  &_input {
    width: 75px;
    font-family: 'IQOS', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: $dark-grey;
    font-size: 16px;

    input {
      padding: 0;
      text-align: center;
    }
  }

  &_comment {
    width: 100%;
    height: 50px;
  }
}
