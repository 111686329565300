@import "src/styles/main.scss";

.form {
  @include flex($align: stretch, $direction: column);

  flex: 1;
  width: 100%;
}

.button_disabled {
  cursor: not-allowed;
  box-shadow: none;
  background: $grey-cloud;
  color: $medium-grey;

  &:hover {
    color: $medium-grey;
  }

  &:after {
    background-color: transparent;
  }
}

.next {
  margin-top: 30px;

  @include sm() {
    margin-top: 25px;
  }
}
