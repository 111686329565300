@import "src/styles/main.scss";

.masked_input {
  @include error();

  position: relative;
}

.input {
  width: 100%;
  padding: 2rem;
  font-family: 'IQOS', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  font-size: 16px;
  text-align: center;
  background: $white;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem 0 0 0.5rem;
  outline: none;
  transition: $transition;

  @include sm() {
    font-size: 2.6rem;
    border-radius: 0.5rem;
  }

  &_invalid {
    color: $red;
  }

  :disabled {
    cursor: not-allowed;
  }
}
