@import "./media.scss";
@import "./variables.scss";

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;

  @include lg {
    font-size: 9px;
  }
  @include md {
    font-size: 8px;
  }
  @include sm {
    font-size: 7px;
  }
}

body {
  font-family: 'IQOS', sans-serif;
  font-style: normal;
  background-color: $white;
  color: $dark-grey;
}

input {
  font-family: 'IQOS', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  font-size: 16px;
}
