@import "src/styles/main.scss";

.wrapper {
  overflow: auto;
  color: $dark-grey;
}

.description {
  margin-bottom: 25px;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
}

.variant {
  padding-bottom: 25px;
  margin-bottom: 25px;
  color: $dark-grey;
  border-bottom: 1px solid #c4c4c4;

  &_title {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: 0px;
  }
}

.list {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
  }
}

.input {
  width: 100%;
  height: 50px;
  padding: 12px 0 12px 20px;
  font-family: 'IQOS', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  font-size: 16px;

  &::placeholder {
    color: $light-grey;
  }
}
