@import "../../../styles/main.scss";


.input {
  margin: 25px 0;
  font-family: 'IQOS', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  font-size: 16px;
  
  &_container {
    width: 100%;
  }
}

.caption {
  width: 100%;
  color: $dark-grey;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0;
}

.content {
  @include flex(center, center, column);

  max-width: 540px;
  margin: 0 auto;

  @include sm() {
    max-width: 100%;
    align-items: flex-start;
  }
}

.button_disabled {
  background-color: $grey-cloud;
  color: $medium-grey;

  &:after {
    background-color: $grey-cloud;
    color: $medium-grey;
  }
}

.emailpmi {
  width: 100%;
  height: 50px;
  padding: 12px 0 12px 20px;
  margin-top: 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
  border: 1px solid $light-grey;
  border-radius: 5px;
  outline: none;
  transition: $transition;

  &:focus {
    border: 1px solid $dark-grey;
  }

  &_error {
    border: 2px solid $red;
  }
}

.emailpmi::-webkit-inner-spin-button {
  display: none;
}

.error {
  display: block;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: red;
}

