@import "src/styles/main.scss";

.item {
  display: grid;
  gap: 12px;

  @include sm() {
    flex: 1;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.control {
  //@include flex(center, flex-start, column);
  
  width: 100%;
  background: $white;
}
