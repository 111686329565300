@import "src/styles/main.scss";

.welcome {
  @include flex(flex-start, flex-start, column);

  flex: 1;
  background: $dark-blue url(../../../assets/images/welcome-and-summary-background-desktop.png) no-repeat;
  background-size: cover;
  background-position: center;

  @include sm() {
    padding: 30px;
    background: $dark-blue url(../../../assets/images/welcome-and-summary-background.png) no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.content {
  @include flex(center, center, column);

  max-width: 540px;
  margin: 0 auto;

  @include sm() {
    max-width: 100%;
    align-items: flex-start;
  }
}

.counter {
  max-width: fit-content;
  align-items: center;
  font-weight: 100;
  margin-top: 5.5rem;
  font-size: 2.6rem;
  color: $black;
  letter-spacing: 0.196rem;
  text-transform: uppercase;
  transition: none;

  @include sm() {
    margin-top: 6rem;
    max-width: 100%;
  }
}

.caption {
  margin-top: 30vh;
  margin-bottom: 15px;
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0;
  text-align: center;
  color: $white;

  @include sm() {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 42px;
    text-align: left;
  }
}

.counter {
  max-width: fit-content;
  align-items: center;
  font-weight: 100;
  font-size: 2.6rem;
  color: $white;
  letter-spacing: 0.196rem;
  text-transform: uppercase;
  transition: none;
  margin-bottom: 3rem;

  @include sm() {
    margin-top: 10px;
    max-width: 100%;
  }
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
  color: $white;

  a:visited,
  a:link {
    color: $black-brown
  }

  @include sm() {
    text-align: left;
  }
}

.button {
  width: 160px;

  @include sm() {
    width: 100%;
  }
}
