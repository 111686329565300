@import "src/styles/main.scss";

.button {
  @include flex(center, center);

  position: relative;
  z-index: 1;
  width: 100%;
  padding: 13.5px 0;
  font-family: 'IQOS', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  outline: none;
  transition: $transition;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: inset 0 0 0 2px transparent;

  &:hover {
    --box-shadow-color: transparent;
  }

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;

    &:after {
      background-color: transparent;
    }
  }

  &:after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    width: 120%;
    height: 120%;
    z-index: -1;
    transition: transform 0.25s ease-out;
    transform: translate(-101%, -50%) skew(0deg);
    opacity: 1
  }

  &_rounded {
    padding: 14px 0;
    border-radius: 50px;
  }

  &_theme {
    &_tertiary {
      color: $dark-grey;
    }

    &_primary {
      background-color: $dark-grey;
      color: $milk-white;

      &:after {
        background-color: $blue;
      }

      &:hover {
        &:after {
          transform: translate(-10%, -50%) skew(-30deg);
        }
      }
    }

    &_quinary {
      background-color: $dark-grey;
      color: $milk-white;
    }

    &_quaternary {
      background: transparent;
      box-shadow: inset 0 0 0 2px $milk-white;
      color: $milk-white;
    }

    &_senary {
      background: transparent;
      box-shadow: inset 0 0 0 2px $dark-grey;
      color: $dark-grey;
    }

    &_secondary {
      background: $milk-white;
      color: $dark-grey;

      &:after {
        background-color: $blue;
      }

      &:hover {
        color: $white;

        &:after {
          transform: translate(-10%, -50%) skew(-30deg);
        }
      }
    }

    &_tertiary,
    &_quaternary {
      &:after {
        background-color: $blue;
      }
    }

    &_quinary,
    &_senary {
      &:after {
        background-color: $milk-white;
      }
    }

    &_primary,
    &_secondary,
    &_quinary,
    &_senary {
      &:disabled {
        background: $grey-cloud;
        color: $medium-grey;

        &:hover {
          color: $medium-grey;
        }
      }
    }

    &_tertiary,
    &_quaternary {
      &:disabled {
        background: $medium-grey;
        color: $milk-white;

        &:hover {
          color: $milk-white;
        }
      }
    }

    &_tertiary {
      background: #F6F4F0;

      &:hover {
        color: $white;

        &:after {
          transform: translate(-10%, -50%) skew(-30deg);
        }
      }
    }

    &_quaternary,
    &_quinary,
    &_senary {
      &:hover {
        color: $dark-grey;

        &:after {
          transform: translate(-10%, -50%) skew(-30deg);
        }
      }
    }
  }

  &_error {
    box-shadow: 0 0 0 2px $red;
  }
}
