@import "src/styles/main.scss";

.summary {
  @include flex(flex-start, center, column);

  flex: 1;
  background: $dark-blue url(../../../assets/images/welcome-and-summary-background-desktop.png) no-repeat;
  background-size: cover;
  background-position: center;

  @include sm() {
    padding: 30px;
    background: $dark-blue url(../../../assets/images/welcome-and-summary-background.png) no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.content {
  @include flex(center, center, column);

  max-width: 540px;
  margin: 0 auto;

  @include sm() {
    max-width: 100%;
    align-items: flex-start;
  }
}

.caption {
  margin-top: 30vh;
  margin-bottom: 15px;
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 0;
  text-align: center;
  color: $white;

  @include sm() {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 42px;
    text-align: left;
  }
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
  color: $white;

  a:visited,
  a:link {
    color: $black-brown
  }

  @include sm() {
    text-align: left;
  }
}

.heart {
  font-size: 5.4rem;
  margin-top: 3rem;
}

.caption,
.description,
.heart {
  text-align: center;
}
