@import "src/styles/main.scss";

.header {
  @include flex(center);

  position: relative;
  z-index: 6;
  width: 100%;
  height: 100px;
  padding: 35px 0;
  background-color: $white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);

  img {
    height: 32px;
  }

  @include sm() {
    height: 70px;
    padding: 25px 0;

    img {
      height: 20px;
    }
  }
}
