@import "src/styles/main.scss";

.confirmation {
  width: 100%;
  padding: 30px;

  button {
    margin-top: 30px;

    @include sm() {
      margin-top: 25px;
    }
  }
}

.content {
  @include flex(center, flex-start, column);

  max-width: 540px;
  margin: 0 auto;

  @include sm() {
    max-width: 100%;
    align-items: flex-start;
  }
}

.caption {
  color: $dark-grey;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0;
}

.input {
  width: 100%;
  height: 50px;
  padding: 12px 0 12px 20px;
  font-family: 'IQOS', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  font-size: 16px;
  border: 1px solid $light-grey;
  border-radius: 5px;
  outline: none;
  transition: $transition;

  &::placeholder {
    color: $light-grey;
  }

  &_focused {
    border: 1px solid $dark-grey;
  }

  &_error {
    border: 2px solid $red;
  }

  &_container {
    position: relative;
    margin-top: 25px;
    width: 100%;
  }

  &_error_icon {
    @include flex(center, center);

    position: absolute;
    top: 15px;
    right: 20px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    background-color: $red;
    color: $white;
    border-radius: 100px;
  }
}

.button_disabled {
  background-color: $grey-cloud;
  color: $medium-grey;

  &:after {
    background-color: $grey-cloud;
    color: $medium-grey;
  }
}
