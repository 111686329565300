@import "src/styles/main.scss";

.radiogroup {
  @include flex(space-between, stretch);

  flex-wrap: wrap;

  @include sm() {
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;
  }
}

.flag {
  @include size(24px);
  @include min-size(24px);

  position: relative;
  border: 1px solid $dark-grey;
  border-radius: 24px;
  transition: $transition;

  &_invalid {
    border-color: $red;
  }

  &__indicator {
    @include size(12px);
    @include min-size(12px);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    background: $dark-grey;
    border-radius: 24px;

    &_active {
      display: block;
    }
  }
}

.radio {
  @include flex($align: center);

  cursor: pointer;

  &--disabled {
    opacity: 0.5;

    & > .label,
    & > .flag {
      cursor: not-allowed;
    }
  }
}

.label {
  color: $dark-grey;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}
