@import "src/styles/main.scss";

.container {
  @include flex(center, center, column);

  cursor: pointer;
  width: 100%;
  height: 225px;
  border: 1px solid $light-grey;
  border-radius: 5px;
  outline: none;

  p {
    margin-top: 10px;
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0;
    color: $dark-grey;
  }
}

.input {
  display: none;
}
