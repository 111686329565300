@import "src/styles/main.scss";

$mdx: 800px;

.container {
  @include error();

  position: relative;
  height: 100%;
}

.sub_container {
  display: grid;
  grid-gap: 50px;

  @media screen and (max-width: $mdx) {
    grid-template-columns: 1fr !important;
    grid-gap: 20px;
    justify-items: center;
  }
}

.textarea {
  width: 100%;
  height: 225px;
  font-size: 16px;
  font-family: 'IQOS', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  padding: 12px;
  font-size: 16px;
  background: $white;
  border: 1px solid $light-grey;
  border-radius: 0.5rem;
  outline: none;
  resize: none;
  transition: $transition;

  &::placeholder {
    color: $light-grey;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &_focused {
    border-color: $dark-grey;
  }

  &_invalid {
    border: 2px solid $red;
  }

  &_disabled {
    opacity: 0.5;
    background: transparent;
    cursor: not-allowed;
  }
}

.textsmallarea {
  width: 100%;
  height: 50px;
  padding: 12px;
  font-size: 16px;
  font-family: 'IQOS', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  color: $dark-grey;
  background: $white;
  border: 1px solid $light-grey;
  border-radius: 0.5rem;
  outline: none;
  resize: none;
  transition: $transition;

  &::placeholder {
    color: $light-grey;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &_focused {
    border-color: $dark-grey;
  }

  &_invalid {
    border: 2px solid $red;
  }

  &_disabled {
    opacity: 0.5;
    background: transparent;
    cursor: not-allowed;
  }
}

.question_image {
  width: 100%;
  height: 34vh;

  @media screen and (max-width: $mdx) {
    width: 80%;
    margin-bottom: 40px;
  }
}
