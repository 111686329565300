@import "src/styles/main.scss";

.controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.selected {
  color: $white;

  &:after {
    transform: translate(-10%, -50%) skew(-30deg);
  }
}
