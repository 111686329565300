@import "src/styles/main.scss";

.flag {
  @include flex(center, center);
  @include size(24px);

  border: 1px solid $dark-grey;
  border-radius: 3px;
  transition: $transition;

  img {
    @include size(100%);
  }

  &_checked {
    background: $dark-grey;
  }

  &_invalid {
    border-color: $red;
  }
}

.checkbox {
  @include flex($align: center);

  position: relative;
  cursor: pointer;

  &_disabled {
    opacity: 0.5;

    & > .label,
    & > .flag {
      cursor: not-allowed;
    }
  }
}

.label {
  margin-left: 10px;
  width: 85%;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
  color: $dark-grey;
}
