@import "src/styles/main.scss";

.container {
  width: 100vw;
  height: 100vh;
  background: $dark-blue url(../../../assets/images/welcome-and-summary-background-desktop.png) no-repeat;
  background-size: cover;
  background-position: center;

  @include sm() {
    background: $dark-blue url(../../../assets/images/welcome-and-summary-background.png) no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.not_found {
  @include flex(center, center, column);

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.caption {
  font-size: 6rem;
  text-align: center;
  color: $white;
}
