@import "src/styles/main.scss";

.track {
  width: 540px;
  max-width: 540px;
  height: 6px;
  background: #F6F4F0;
  border-radius: 22px;
  margin: 30px auto 70px;

  &__progress {
    height: 100%;
    background: $blue;
    border-radius: 22px;
  }

  @include sm() {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 25px;
  }
}
