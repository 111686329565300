// Color Palette
$white: #fff;
$white-light: #e9e9e9;
$black: #000000;
$black-light: #282828;
$gray: #767676;
$primary: #d7d9e2;
$upload-white: #ebecf1;
$upload-text: ghostwhite;
$black-brown: #a26f26;

// New colors
$red: #c73636;
$dark-blue: #003C52;
$blue: #005D7E;
$dark-grey: #34303d;
$milk-white: #fffdfb;
$grey-cloud: #b8b7bb;
$light-grey: #99979E;
$medium-grey: #7b7881;

// Misc
$transition: 0.25s;
