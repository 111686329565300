@import "src/styles/main.scss";

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  width: 100%;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
  color: $dark-grey;
}
